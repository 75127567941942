<template>
	<div v-cloak class="container">
        <img src="@/assets/img_bg@2x.png" alt="" class="container-bg">
		<div class="header">
            <!-- <van-skeleton :row="5" :loading="loading" class="banner-skeleton"></van-skeleton> -->
            <!-- <input type="text" v-model="testTime"><button @click="testTime1">点我</button>
            <button @click="testClearInfo">点我清除考试信息</button>
            <div>服务器时间：{{ testLocalTime }}</div> -->
            <!-- <div style="position:absolute; top:0; left:50%;" @click="goTest">跳转清除页</div> -->
            <van-skeleton title avatar :row="3" :loading="loading" style="margin-top:7rem">
			<div class="info-card">
				<div class="card-in">
                    <div class="mark" flex="main:left cross:center" :style="{backgroundImage:colorMap[gameStatus.tipStatus].before}">
                        <div class="icon" :style="{borderLeft:colorMap[gameStatus.tipStatus].after}"></div>
                        <div style="text-indent:0.13rem">{{ gameStatus.tip }}</div>
                    </div>
                    <router-link to="/score" class="score-btn" v-if="intoScore" tag="div">查看成绩</router-link>
					<div v-if="!studInfo.schoolName">
						<div class="tip">{{ wxAuth?'请先绑定学生':'您还未进行微信授权' }}</div>
						<div class="bind-btn" flex="main:center cross:center" @click="bindClick">
                            <img src="@/assets/bind.png" alt="" class="bind-icon">
                            <span>{{ wxAuth?'绑定学生':'立即授权' }}</span>
                        </div>
					</div>
                    <div v-else>
                        <div class="info" flex="main:center">
                            <div class="left" flex="main:center" @click="goInfo">
                                <img :src="studInfo.avatar" alt="" class="photo">
                                <div style="height:100%">
                                    <div class="name">{{ studInfo.name }}</div>
                                    <div class="school">{{ studInfo.schoolName }}</div>
                                </div>
                            </div>
                            <div :class="{'right':true,'unclick':unclick}" flex="main:center cross:center" @click="begainGame">开始测评</div>
                        </div>
                    </div>
				</div>
			</div>
            </van-skeleton>
		</div>
		<!-- 中途退出再次进入弹窗显示 -->
		<van-overlay :show="enterAgain" @click="enterAgain = false">
			<div class="enter-again">
				<div class="dialog-title">上次中途退出</div>
				<div class="time">倒计时：{{ timeRemaning }}</div>
				<van-button class="answer-again" type="primary" @click="answerAgain">继续答题</van-button>
			</div>
		</van-overlay>
        <!-- 未开始 点击开始 -->
        <van-overlay :show="notStart" @click="notStart = false" z-index="1">
			<div class="tip-box" flex="dir:top cross:center" @click.stop>
                <div class="title">请在测评时间内答题 </div>
                <div class="time-tip">- 下轮测评时间 -</div>
                <!-- <div class="time" style="text-align: center">11月12/19/26日和12月3日<span style="display: block">8:00-22:00</span></div> -->
<!--                <div class="time" style="text-align: center">12月10日<span style="display: block">19:00-19:25</span></div>-->
        <div class="time">
          <div>{{ nextScopes.nextDay }}</div>
          <div>{{ nextScopes.nextTime }}</div>
        </div>
                <div class="btn" flex="main:center cross:center" @click="notStart = false">好的</div>
            </div>
		</van-overlay>
        <!-- 已有作答成绩 点击开始 -->
        <van-overlay :show="againGame">
			<div class="tip-box" flex="dir:top cross:center">
                <div class="title">{{ store.gameType == 1 ? '今日' : '' }}不可重复作答</div>
                <div class="btn" flex="main:center cross:center" @click="againGame = false">好的</div>
            </div>
		</van-overlay>
        <!-- 初赛结束 -->
        <van-overlay :show="firstGameOver">
			<div class="tip-box" flex="dir:top cross:center">
                <div class="title">初选已结束</div>
                <div class="btn" flex="main:center cross:center" @click="firstGameOver = false">好的</div>
            </div>
		</van-overlay>
        <!-- 复赛结束 -->
        <van-overlay :show="gameOver">
			<div class="tip-box" flex="dir:top cross:center">
                <div class="title">复选已结束</div>
                <div class="btn" flex="main:center cross:center" @click="gameOver = false">好的</div>
            </div>
		</van-overlay>
        <!-- 已经晋级 -->
        <van-overlay :show="adopt">
			<div class="tip-box" flex="dir:top cross:center">
                <div class="title">您已晋级，请勿重复点击</div>
                <div class="btn" flex="main:center cross:center" @click="adopt = false">好的</div>
            </div>
		</van-overlay>
        <!-- 未晋级 -->
        <van-overlay :show="disAdopt">
			<div class="tip-box" flex="dir:top cross:center">
                <div class="title">您未晋级，请勿重复点击</div>
                <div class="btn" flex="main:center cross:center" @click="disAdopt = false">好的</div>
            </div>
		</van-overlay>
	</div>
</template>

<script>
	import dataApi from "@/api/apply.js";
    import {
        store,
        mutations
    } from "@/store/index.js";
	import {
		wxLogin
	} from "@/mixins/wx.js";
	export default {
		data() {
			return {
				name: "",
				photo: "",
				isBind: false,
				STATE: "",
				enterAgain: false, // 中途退出后再次进入
				unclick: true,
				answerTime: 0, // 考试时间 （单位：秒）
				timeRemaning: "", //  考试剩余时间  00:00
				timer: null,
				gameTimer:null,
                statuTime:null,
                notStart:false,
                againGame:false,
                firstGameOver:false,
                adopt:false,
                disAdopt: false,
                gameOver: false,
                store: store,
                colorMap:{
                    1:{
                        before:'linear-gradient(270deg, #FF9E39 0%, #FE7B4A 100%)',
                        after:'0.15rem solid #FF9E39'
                    },
                    2:{
                        before:'linear-gradient(-89deg, #2ABEFD 0%, #0C9AE9 100%)',
                        after:'0.15rem solid #2ABEFD'
                    },
                    3:{
                        before:'linear-gradient(-89deg, #A6BAC3 0%, #899FAB 100%)',
                        after:'0.15rem solid #A6BAC3'
                    },
                    4:{
                        before:'linear-gradient(270deg, #FF9E39 0%, #FE7B4A 100%)',
                        after:'0.15rem solid #FF9E39'
                    },
                    5:{
                        before:'linear-gradient(-89deg, #2ABEFD 0%, #0C9AE9 100%)',
                        after:'0.15rem solid #2ABEFD'
                    },
                    6:{
                        before:'linear-gradient(-89deg, #A6BAC3 0%, #899FAB 100%)',
                        after:'0.15rem solid #A6BAC3'
                    }
                },
                gameStatus: {
                    tipStatus:1,
                    tip:'初选未开始'
                },
        nextScopes: {
          nextDay: '',
          nextTime: ''
        },
                studInfo: {},
                allowEval: {},
                wxAuth: false,
                intoScore:false,
                loading:true
			};
		},
		mixins: [
			// 微信授权
			wxLogin
		],
        async mounted() {
            document.addEventListener('visibilitychange',this.windowActive);
             //轮询首页状态
            this.loopStatus()
            this.statuTime = setInterval(()=>{
                this.loopStatus()
            },60*1000)
            //查看成绩按钮功能
            dataApi.getScore().then(res=>{
                if(res.data.length){
                    this.intoScore = true
                }else{
                    this.intoScore = false
                }
            })
        },
		methods: {
            goTest(){
                this.$router.push('/testScore')
            },
			authClick() {
				//重新拉取授权
				let urlRouter = window.location.href.split("#/")[0]; // 当前路由
				dataApi
					.getAuth({
						redirectUrl: urlRouter,
					})
					.then((res) => {
						window.location.replace(res.data.url);
					});
			},
			applyAction() {
				if (this.STATE == "GAME_END" || this.STATE == "ERROR" ) {
					return;
				}
				if (this.STATE == "GAME_ING") {
					//用户已报名，比赛开始，进入答题，首先进入答题须知
					this.$router.push("/ExamRead");
					return;
				} else if (this.STATE == "BACK_GAME") {
					//用户断线重新连接
					this.$router.push("/AnswerCard");
					return;
				}
			},
			//清除用户绑定信息，测试需要
			clear() {
				dataApi.clearInfo().then(() => {});
			},
			checkApply() {
				// 检查是否在报名时间内  ( 按钮的功能 )
				dataApi.checkState().then((res) => {
					const data = res.data;
					this.STATE = data.allowEval.code;
                    this.studInfo = data.studInfo
                    this.allowEval = data.allowEval
                    this.gameStatus = data.gameStatus
          this.nextScopes = data.nextScopes || {}
                    this.loading = false
                    mutations.setGameType(data.gameStatus.gameType)
                   if(this.gameStatus.tipStatus == 2 || this.gameStatus.tipStatus == 5){ //开始按钮亮起
                        this.unclick = false
                    }else {
                        this.unclick = true
                    }
                    if(this.allowEval.lastTime>0){
                        this.checkGameBegain()
                    }
				});
			},
			async checkGameBegain(){     //首页查询开始时间
				let count = this.allowEval.lastTime
                clearInterval(this.gameTimer)
				this.gameTimer = setInterval(()=>{
					if(count <=0){
						clearInterval(this.gameTimer)
						this.checkApply()
					}
					count--
				},1000)
			},
			answerAgain() {
				this.enterAgain = false;
				this.$router.push("/AnswerCard");
			},
			goRank() {
				this.$router.push("/rank");
			},
            async begainGame() {
                // await this.checkApply()
                if(this.STATE == 'ALREADY_PLAY') {
                    this.againGame = true
                }else if(this.STATE == 'PASS'){
                    this.adopt = true
                }else if(this.STATE == 'GAMING'){
                    this.$router.push('/AnswerCard')
                }else if(this.STATE == 'GAME_NOT_BEGIN'){
                    this.notStart = true
                }else if(this.STATE == 'GAME_END'){
                    this.firstGameOver = true
                }else if(this.STATE == 'NOT_PASS'){
                    this.disAdopt = true
                }else if(this.STATE == 'GAME_OVER'){
                    this.gameOver = true
                }
                if(this.allowEval.lastTime>0) {
                    this.notStart = true
                }
                if(this.STATE == 'OK' && this.allowEval.lastTime<=0){
                    this.$router.push('/ExamRead')
                }

            },
            //首页状态
            loopStatus() {
                dataApi.getStatus().then(res=>{
                    const data = res.data
                    let timer = null
                    if(data.lastTime <= 300){
                        timer = null
                        timer = setTimeout(()=>{
                            this.gameStatus = data
                            if(this.gameStatus.tipStatus == 2 || this.gameStatus.tipStatus == 5){ //开始按钮亮起
                                this.unclick = false
                                this.checkApply()
                            }else {
                                this.unclick = true
                            }
                        },data.lastTime*1000)
                    }
                })
            },
            bindClick() {  //绑定-授权
                if(this.wxAuth){
                    this.$router.push({
                        name: 'BindInfo',
                        query: {
                            code: this.allowEval.code,
                            no: this.allowEval.no
                        }
                    })
                }else {
                    this.authClick()
                }
            },
            goInfo(){
                if(this.studInfo.schoolName){
                    this.$router.push({
                        name: 'BindInfo',
                        query: {
                            code: this.allowEval.code,
                            no: this.allowEval.no
                        }
                    })
                }
            },
            windowActive() {
                if (document.visibilityState === 'hidden') {
                    // console.log();
                }
                // 用户打开或回到页面
                if (document.visibilityState === 'visible') {
                    location.reload()
                }
            }
		},
		beforeDestroy() {
            document.removeEventListener('visibilitychange', this.windowActive)
			clearInterval(this.timer);
			clearInterval(this.gameTimer);
            clearInterval(this.statuTime);
		},
	};
</script>

<style scoped="scoped" lang="less">
    .overHide{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
	.container{
		min-height: 100%;
	}
    .container-bg{
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
	.header{
        overflow: hidden;
        .banner-skeleton{
            margin-top: 0.4rem;
            .van-skeleton__row{
                width: 100%!important;
                height: 0.4rem;
            }
        }
		.info-card{
			width: 6.9rem;
			height: 2.82rem;
			margin: auto;
			background-color: #fff;
			overflow: hidden;
            position: relative;
            margin-top: 6.8rem;
            border-radius: 7px;
		}
		.card-in{
			width: 6.6rem;
			height: 2.56rem;
			margin: auto;
			background-image: url('../assets/info-card.png');
			background-origin: content-box;
			background-size: 100%;
			margin-top: 0.13rem;
			overflow: hidden;
			.tip{
				font-size: 0.32rem;
				color: #333333;
				height: 0.48rem;
				line-height: 0.48rem;
				margin-top: 0.66rem;
				text-align: center;
				margin-bottom: 0.28rem;
				font-weight: bold;
			}
            .mark{
                min-width: 1.66rem;
                height: 0.48rem;
                position: absolute;
                left: 0;
                top: 0.37rem;
                box-shadow: 0 0.04rem 0.24rem 0;
                font-size: 0.26rem;
                color: #FFFFFF;
                background-image: linear-gradient(270deg, #FF9E39 0%, #FE7B4A 100%);
                text-indent: 0.13rem;
                .icon{
                    width: 0;
                    height: 0;
                    border-top: 0.25rem solid transparent;
                    border-left: 0.15rem solid #FF9E39;
                    border-bottom: 0.25rem solid transparent;
                    position: absolute;
                    right: -0.14rem;
                }
            }
            .score-btn{
                font-size: 0.24rem;
                color: #27C190;
                height: 0.36rem;
                position: absolute;
                right: 0.39rem;
                top: 0.39rem;
            }
            .info{
                width: 100%;
                height: 0.88rem;
                margin-top: 1.16rem;
                justify-content: space-between;
                align-items: center;
                .photo{
                    width: 0.88rem;
                    height: 0.88rem;
                    margin-right: 0.16rem;
                    border-radius: 50%;
                }
                .left{
                    margin-left: 0.39rem;
                }
                .right{
                    margin-right: 0.39rem;
                    background: #27C190;
                    border-radius: 0.48rem;
                    width: 1.64rem;
                    height: 0.56rem;
                    font-size: 0.26rem;
                    color: #FFFFFF;
                }
                .unclick{
                    background: #27C190;
                    opacity: 0.5;
                }
                .name{
                    font-size: 0.32rem;
                    color: #333333;
                    font-weight: bold;
                    height: 0.48rem;
                    line-height: 0.48rem;
                    max-width: 2.5rem;
                    .overHide
                }
                .school{
                    font-size: 0.24rem;
                    color: #999999;
                    max-width: 2.5rem;
                    margin-top: 0.04rem;
                    .overHide
                }
            }
		}
		.bind-btn{
			width: 5.78rem;
			height: 0.72rem;
			border: 0.02rem solid #27C190;
			border-radius: 0.08rem;
			border-radius: 0.08rem;
			margin: auto;
			font-size: 0.34rem;
			color: #27C190;
			font-weight: bold;
		}
		.bind-icon{
			width: 20px;
			height: 20px;
			margin-right: 0.08rem;
			transition: 0.2s all ease-in;
		}
		.bind-btn:active{
			background-color: #e0f2ed;
			opacity: 0.8;
		}
	}



	.enter-again {
		height: auto;
		width: 5.9rem;
		background-color: #fff;
		margin: 0 auto;
		margin-top: 4.37rem;
		text-align: center;
		overflow: hidden;
		border-radius: 0.08rem;

		.answer-again {
			width: 5.1rem;
			height: 0.72rem;
			background-color: #186498;
			font-size: 0.28rem;
			color: #fff;
			border: none;
			margin-bottom: 0.48rem;
		}

		.title {
			margin-top: 0.1rem;
		}

		.dialog-title {
			color: #333;
			font-size: 0.4rem;
			margin-top: 0.48rem;
			font-weight: bold;
		}

		.time {
			color: #666;
			font-size: 0.28rem;
			margin-top: 0.32rem;
		}
	}
    .tip-box{
        width: 5.9rem;
        height: auto;
        background-color: #fff;
        box-shadow: 0 0 0.3rem 0 rgba(0,0,0,0.24);
        border-radius: 0.08rem;
        margin: 0 auto;
		margin-top: 4.37rem;
        padding-bottom: 0.48rem;
        .title{
            font-size: 0.36rem;
            color: #333333;
            height: 0.54rem;
            line-height: 0.54rem;
            margin-top: 0.48rem;
        }
        .time-tip{
            font-size: 0.3rem;
            color: #333333;
            height: 0.46rem;
            line-height: 0.46rem;
            margin-top: 0.24rem;
        }
        .time{
            height: 0.46rem;
            line-height: 0.46rem;
            font-size: 0.3rem;
            color: #2DB2EA;
            text-align: center;
        }
        .btn{
            background: #27C190;
            border-radius: 0.08rem;
            font-size: 0.32rem;
            color: #FFFFFF;
            width: 4.5rem;
            height: 0.72rem;
            margin-top: 0.5rem;
        }
    }
</style>
